import React from "react";
import "./main.styles.css";
import Header from "../Header/header.component";

function Main() {
  return (
    <section className="welcome-section">
      <Header />
      <div id="welcome-background" />
      <div className="main-container">
        <div className="main-title">
          <p id="title-first-row" className="main-title-h1">
            {" "}
            <span>Oh, hello!</span>{" "}
          </p>
          <p id="title-second-row" className="main-title-h1 ">
            <span>My name is Pamella, </span>
          </p>
          <div className="third-row-wrapper">
            <p id="title-third-row" className="main-title-h1">
              <span>but you can call me</span>{" "}
            </p>
            <svg
            id="pam-svg"
              width="145"
              height="92"
              viewBox="0 0 145 92"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
              id="pam-svg-p1"
                d="M7 21C7 22.127 9.09112 17.951 9.6558 16.9756V16.9756C10.4593 15.5878 12.99 11.2059 13.9242 9.90248C14.1829 9.54164 14.4016 9.22564 14.5286 9C15.1166 7.95461 14.5286 12.864 14.5286 14.0634C14.5286 21.1297 14 27.9213 14 35C14 43 14 51.5 13.5 59.5C13.5 63.8569 13.1921 68.127 12.7964 72.4794C12.4758 76.0068 12.4729 86.6424 12.4729 83.1004"
                stroke="#C4747C"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
              id="pam-svg-p2"
                d="M15.2138 15.9478C15.1246 16.6608 15.5386 16.0223 15.899 15.529C16.6275 14.5321 17.4498 14.0334 18.4496 13.3972C19.963 12.4341 21.5685 11.99 23.0939 11.2273C27.0624 9.24305 29.11 8.41023 33.5 8.41023C35 8.41023 39.7932 8.31539 41.5952 8.41023C43 8.48417 46.4148 9.91476 47.5 11C48.9666 12.4666 50.3438 13.9063 51 15.9478C51.4737 17.4214 51.3608 20.4665 51 22C50.0918 25.86 46.55 30 43.9173 32.3933C40.5 35.5 36.9563 38.2214 33.5 40C29.5 42.5 25.7432 44.6179 22 47C20.8835 47.7105 19.7388 48.4208 18.7161 49.2576C17.6933 50.0944 14.3798 51.7404 14 52.5"
                stroke="#C4747C"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
              id="pam-svg-a"
                d="M75.1715 81.73C73.6776 81.73 72.5433 73.6054 72.1641 71.9464C71.0427 67.0404 70.3749 61.4309 70.3749 56.3955C70.3749 54.3791 70.8132 51.9725 70.3749 50C69.8766 47.7575 69.8973 45.7349 69.5 43.5C68.9727 40.5337 69.066 34.2878 66.5871 32.222C60.0976 26.8141 55.0024 36.3855 52 40.5C48.4345 45.3861 47.6304 51.1566 45.8969 56.8523C44.5307 61.3413 43.9935 66.1705 43.9935 70.8424C43.9935 73.6164 44.784 80.6137 48.6759 80.7021C54.2556 80.8289 56.9281 75.4909 60 71.9464C62.7832 68.735 64.497 64.2896 67 61C69.4711 57.7522 68.8697 56.1894 70.2887 52.5"
                stroke="#C4747C"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
              id="pam-svg-m"
                d="M83 27C83 27.7787 83.3922 28.7685 83.5888 29.5548C83.963 31.0515 84.0986 32.068 84.2516 33.5979C84.3828 34.9098 84.2516 37.1754 84.2516 38.5025C84.2516 41.3031 84.3973 43.4895 84.2516 46.2573C84.0976 49.1843 84.2516 52.1544 84.2516 55.0857C84.2516 61.4569 84.823 67.7672 84.9674 74.1212C84.9962 75.385 85.206 76.6328 85.206 77.8726C85.206 79.1493 85.6184 80.3389 85.6832 81.571C85.7192 82.2536 85.9789 82.7846 86.1472 83.3738C86.2859 83.8594 86.8257 81.5314 86.9293 81.0938C87.3655 79.2522 87.2282 77.357 87.3667 75.4865C87.4956 73.7477 88.0985 72.0626 88.3609 70.3565C88.8286 67.3165 89.4342 64.2216 90.0312 61.2364C90.4351 59.2167 90.998 57.2026 91.5158 55.205C92.0071 53.31 92.1134 51.3088 92.6028 49.4122C93.5335 45.8059 94.6039 42.143 95.2805 38.4893C95.5848 36.8461 96.0916 35.1241 96.7784 33.5979C97.1642 32.7406 97.3551 31.2251 98.568 31.2251C99.6113 31.2251 99.7138 30.6417 100.238 31.8216C100.705 32.8713 101.365 33.9116 101.617 35.0428C101.954 36.5581 102.272 38.4634 102.5 40C102.695 41.3158 102.806 42.2777 103.048 43.5796C103.538 46.2098 103.871 48.9676 104.586 51.5596C105.3 54.1465 105.562 56.808 106.15 59.3806C106.442 60.6565 106.15 59.8183 106.442 62.2439C106.592 63.4923 107 64.5 107 65.5C107 66.5 107.196 68 107.383 69.0309C107.531 69.8416 107.5 71 108.112 71.5495C108.152 72.191 108.549 70.3392 108.722 69.7202C109.266 67.7782 109.645 65.7017 110.074 63.7285C110.457 61.9694 111.083 60.1653 111.572 58.4262C112.024 56.8179 112.213 55.1447 112.646 53.4154C113 52 113.323 50.9135 113.5 49.5C113.732 47.64 114.662 45.1654 115.323 43.394C115.95 41.7148 116.844 40.1554 117.537 38.5025C118.201 36.9197 118.762 34.8242 120.043 33.6111C121.699 32.0415 123.708 29.6654 125.292 32.6567C127.492 36.8128 127.917 41.8919 127.917 46.5489C127.917 51.2217 128.5 55.8146 128.5 60.5C128.5 64.8369 129.322 68.3773 130.5 72.5C131.142 74.7482 132.109 77.6274 133.405 79.6091C133.942 80.4307 135.092 82.855 136 83.3738"
                stroke="#C4747C"
                strokeWidth="5"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <p id="sub-title-main">
            I am a computer science student, and here you can learn more about
            me
          </p>
        </div>
      </div>
    </section>
  );
}

export default Main;
